import React, { useEffect, useState } from "react";
const Img = (props) => {
  const [loaded, setLoaded] = useState(false);
  const [contador, setContador] = useState(0);
  useEffect(() => {}, [contador]);
  return (
    <img
      src={props.src}
      loading="lazy"
      alt={"img"}
      style={
        loaded
          ? props.style
          : { backgroundColor: "gray", width: "100%", height: "100%" }
      }
      onClick={props.onClick}
      onLoad={() => {
        setLoaded(true);
        setContador(contador + 1);
      }}
    />
  );
};

export default Img;
