import "./App.css";
import { Route, Routes, Navigate } from "react-router-dom";
import Home from "./components/home/home";
import Layout from "./components/home/layout";
import Contacto from "./components/contacto/Contacto";
function App() {
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path="/" element={<Home />} />
        <Route path="/contacto" element={<Contacto />} />
      </Route>
    </Routes>
  );
}

export default App;
