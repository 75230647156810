import React from "react";
import Formulario from "../../helpers/form";
import { Container, Row } from "react-bootstrap";

const Contacto = () => {
  const afterSubmit = (formConfig) => {
    return new Promise((resolve, reject) => {
      let body = {};
      formConfig.formConfig.map((actual, indice) => {
        if (indice == formConfig.formConfig.length - 1) {
          body[actual.name] = actual.value;
        } else {
          body[actual.name] = actual.value;
        }
      });
    });
  };
  return (
    <Container fluid>
      <Formulario
        formConfig={{
          title: "Formulario de Contacto",
          submitTitle: "Enviar solicitud",
          customSubmit: true,
          formConfig: [
            {
              name: "nombre",
              type: 1,
              required: true,
              title: "Nombre Completo",
              placeholder: "Ingresa tu nombre completo",
              value: "",
            },
            {
              name: "email",
              type: 4,
              required: true,
              title: "Correo Electrónico",
              placeholder: "Ingresa tu email",
              value: "",
            },
            {
              name: "telefono",
              type: 1,
              required: true,
              title: "No. Teléfono",
              placeholder: "Ingresa tu número de telefono",
              value: "",
            },
            {
              name: "interes",
              type: 8,
              required: true,
              title: "Producto de interés",
              placeholder: "Describe acá el producto de tu interés para cotizar",
              value: "",
            },
          ],
        }}
        afterSubmit={afterSubmit}
      />
    </Container>
  );
};

export default Contacto;
