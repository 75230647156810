import React from "react";
import { Outlet } from "react-router-dom";
import { Container, Row, Col, Navbar, Nav } from "react-bootstrap";
const Layout = () => {
  return (
    <Container fluid style={{ height: "100vh" }}>
      <Row
        className="d-flex align-items-center"
        style={{ height: "5%", backgroundColor: "black", color: "white", zIndex:'2' }}
      >
        <p className="m-auto" style={{ textAlign: "center" }}>
          SERVICIO EN TODA CENTROAMÉRICA
        </p>
      </Row>
      <Row style={{ height: "5%" }}>
        <Navbar bg="light" data-bs-theme="light">
          <Container fluid>
            <Navbar.Brand className="mx-auto" href = '/'>
              <strong style={{ letterSpacing: "10px" }}>SMART EVENTS</strong>
            </Navbar.Brand>
          </Container>
        </Navbar>
      </Row>
      <Row style={{ height: "80%", overflowY: "scroll" }}>
        <Outlet />
      </Row>
      <Row style={{ maxHeight: "10%" }}>
        <Navbar bg="light" data-bs-theme="light">
          <Container fluid>
            <Nav.Link href="contacto">
              <strong>COTIZA AHORA</strong>
            </Nav.Link>
            <Navbar.Brand className="ms-auto">
              <strong>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-telephone-fill"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z"
                  />
                </svg>
                &nbsp; +502 0000 - 0000
              </strong>
            </Navbar.Brand>
          </Container>
        </Navbar>
      </Row>
    </Container>
  );
};

export default Layout;

/*

       <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-telephone-fill"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z"
                />
              </svg>
              &nbsp;&nbsp;&nbsp;&nbsp; +502 0000 - 0000
*/
