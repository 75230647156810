const color_battery = ["#82D4BB", "#82C09A", "#82AC9F", "#829298", "#94778B"];
const shuffleArray = (arr) => {
  // Clone the array to avoid modifying the original array
  const shuffledArray = [...arr];

  // Fisher-Yates shuffle algorithm
  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    // Swap elements at i and j
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }

  return shuffledArray;
};
const chunkArray = (array, chunkSize) => {
  if (chunkSize <= 0) {
    return array;
  } else {
    const result = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      result.push(array.slice(i, i + chunkSize));
    }
    return result;
  }
};
const getRandomColor = () => {
  // Generate a random index within the length of the array
  const randomIndex = Math.floor(Math.random() * color_battery.length);

  // Return the color at the randomly generated index
  return color_battery[randomIndex];
};
export { shuffleArray, chunkArray, getRandomColor };
