var AWS = require("aws-sdk");

const getObjects = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      AWS.config.update({
        region: process.env.REACT_APP_AWS_REGION,
        accessKeyId: process.env.REACT_APP_AKY,
        secretAccessKey: process.env.REACT_APP_SKY,
      });
      var s3 = new AWS.S3();
      const params = {
        Bucket: process.env.REACT_APP_BCKT,
      };
      const getResult = await s3.listObjectsV2(params).promise();
      const salida = getResult.Contents.map((object) => {
        return `https://${process.env.REACT_APP_BCKT}.s3.amazonaws.com/${object.Key}`;
      });
      resolve({
        ret: 1,
        msg: "ok",
        result: getResult,
        salida: salida,
        status: true,
      });
    } catch (error) {
      console.log(error);
      reject({ ret: 0, msg: error });
    }
  });
};

module.exports = {
  getObjects,
};
