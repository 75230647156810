import React, { useEffect, useState } from "react";
import { Container, Row, Carousel, Col, Button } from "react-bootstrap";
import "./style.css";
import Img from "../../helpers/Img";
const S3Controller = require("../../helpers/S3.controller");
const helpers = require("../../helpers/helpers");

const Home = () => {
  const [grupo1, setGrupo1] = useState([]);
  const [grupo2, setGrupo2] = useState([]);
  const [grupo3, setGrupo3] = useState([]);
  const [contador] = useState(0);
  useEffect(() => {
    S3Controller.getObjects()
      .then((response) => {
        let grupos = helpers.chunkArray(
          helpers.shuffleArray(response.salida),
          24
        );
        let grupo_1 = helpers.chunkArray(grupos[0], 4);
        let grupo_2 = helpers.chunkArray(grupos[1], 3);
        let grupo_3 = helpers.chunkArray(grupos[2], 3);
        setGrupo1(grupo_1);
        setGrupo2(grupo_2);
        setGrupo3(grupo_3);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [contador]);
  return (
    <Container fluid className="mt-5">
      <Row>
        <Carousel controls={false} fade>
          {grupo2.map((actual, indice) => (
            <Carousel.Item key={"carrusel-item-" + indice}>
              <Container
                fluid
                style={{
                  backgroundColor: "#90D304",
                  padding: "3%",
                }}
              >
                <Row>
                  {actual.map((current, index) => (
                    <Col
                      className="mt-2"
                      xs={12}
                      md={6}
                      lg={4}
                      key={"foto-item-" + index}
                    >
                      <Img
                        loading="lazy"
                        src={current}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "contain",
                        }}
                      />
                    </Col>
                  ))}
                </Row>
              </Container>
            </Carousel.Item>
          ))}
        </Carousel>
      </Row>
      <Row className="mt-5">
        <h1 style={{ textAlign: "center", fontStyle: "oblique" }}>
          Ilumina tus Momentos, Conecta con Experiencias Inolvidables.
        </h1>
      </Row>
      <Row style={{ padding: "5%" }}>
        <Button href="contacto" variant="outline-dark">
          COTIZA AHORA
        </Button>
      </Row>
      <Row>
        <Container fluid>
          {grupo1.map((actual, indice) => (
            <Row key={"row-number-" + indice} className="mt-5">
              {actual.map((current, index) => (
                <Col
                  xs={12}
                  md={6}
                  lg={3}
                  key={"col-number-" + index}
                  className="mt-5"
                >
                  <Container fluid>
                    <Row style={{ overflow: "hidden" }}>
                      <Img
                        loading="lazy"
                        src={current}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                        className="img-col"
                      />
                    </Row>
                    <Row>
                      <p
                        style={{ textAlign: "center", color: "#6b6b6b" }}
                        className="mt-2"
                      >
                        <strong>Lorem ipsum dolor sit amet</strong>
                        <br />Q 75.00
                      </p>
                    </Row>
                  </Container>
                </Col>
              ))}
            </Row>
          ))}
        </Container>
      </Row>
      <Row>
        <Container fluid>
          {grupo3.map((actual, indice) => (
            <Row key={"row-number-final-" + indice} className="mt-5">
              {actual.map((current, index) => (
                <Col
                  xs={12}
                  md={6}
                  lg={4}
                  key={"col-number-final-" + index}
                  className="mt-5"
                >
                  <Container
                    fluid
                    style={{
                      width: "100%",
                      height: "700px",
                      backgroundImage: `url(${current})`,
                    }}
                  >
                    <Row style={{ height: "100%", padding: "5%" }}>
                      <Container
                        fluid
                        style={{ height: "100%" }}
                        className="d-flex flex-column justify-content-end"
                      >
                        <Row>
                          <h3 style={{ color: "white" }}>
                            <strong>Lorem ipsun</strong>
                          </h3>
                        </Row>
                        <Row>
                          <Col xs={4} md={4} lg={4}>
                            <Button href="contacto" variant="outline-light">
                              COTIZAR AHORA
                            </Button>
                          </Col>
                        </Row>
                      </Container>
                    </Row>
                  </Container>
                </Col>
              ))}
            </Row>
          ))}
        </Container>
      </Row>
    </Container>
  );
};

export default Home;
